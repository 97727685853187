import './main.css';
import { jsxs as p, jsx as t, Fragment as _ } from "react/jsx-runtime";
import { AlertGroup as Fe, Alert as Oe, AlertVariant as B, AlertActionCloseButton as Ne, Modal as Pe, Button as A, FormHelperText as G, HelperText as j, HelperTextItem as z, Popover as Ve, Icon as H, FormGroup as ce, NumberInput as qe, ValidatedOptions as w, InputGroup as K, InputGroupItem as E, TextInput as D, Switch as _e, TextArea as de, Title as De, Card as Be, CardHeader as Le, CardTitle as He, CardBody as Ee, Grid as Me, GridItem as Q, PageSection as $e, JumpLinks as Ue, JumpLinksItem as Ge, ButtonVariant as X, Checkbox as je, Radio as ze, Text as Ke, Brand as We, Avatar as Je } from "@patternfly/react-core";
import { createContext as ue, useContext as me, useState as R, useCallback as he, useEffect as fe, useMemo as x, forwardRef as Ye, useId as Qe, Fragment as ge } from "react";
import { ExclamationCircleIcon as Xe, HelpIcon as Z, EyeIcon as Ze, EyeSlashIcon as et, CubeIcon as tt, PaypalIcon as nt, InstagramIcon as rt, BitbucketIcon as lt, MicrosoftIcon as at, TwitterIcon as st, StackOverflowIcon as it, OpenshiftIcon as ot, LinkedinIcon as ct, GoogleIcon as dt, GitlabIcon as ut, FacebookSquareIcon as mt, GithubIcon as ht, MinusCircleIcon as ft, PlusCircleIcon as gt } from "@patternfly/react-icons";
import { useFormContext as W, Controller as N, useController as J, FormProvider as pt, useWatch as yt } from "react-hook-form";
import { useTranslation as pe } from "react-i18next";
import { Select as ye, SelectVariant as ee, SelectOption as be, Dropdown as bt, KebabToggle as vt, DropdownToggle as It, DropdownItem as te, PageHeader as Ct, PageHeaderTools as Tt, PageHeaderToolsGroup as wt, PageHeaderToolsItem as L } from "@patternfly/react-core/deprecated";
import { get as St } from "lodash-es";
import q from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as xt } from "@patternfly/react-styles";
const ve = ue(void 0), gn = () => me(ve), pn = ({ children: e }) => {
  const [n, r] = R([]), l = (i) => {
    r((o) => o.filter((c) => c.id !== i));
  }, a = (i, o = B.success, c) => {
    r([
      {
        id: Math.random() * 100,
        message: i,
        variant: o,
        description: c
      },
      ...n
    ]);
  }, s = (i) => {
    a(i, B.danger);
  };
  return /* @__PURE__ */ p(ve.Provider, { value: { addAlert: a, addError: s }, children: [
    /* @__PURE__ */ t(Fe, { isToast: !0, "data-testid": "alerts", children: n.map(({ id: i, variant: o, message: c, description: d }) => /* @__PURE__ */ t(
      Oe,
      {
        isLiveRegion: !0,
        variant: B[o],
        variantLabel: "",
        title: c,
        actionClose: /* @__PURE__ */ t(
          Ne,
          {
            title: c,
            onClose: () => l(i)
          }
        ),
        timeout: !0,
        onTimeout: () => l(i),
        children: d && /* @__PURE__ */ t("p", { children: d })
      },
      i
    )) }),
    e
  ] });
};
function kt(e, n) {
  const r = ue(n);
  return r.displayName = e, r;
}
function Rt(e) {
  return e != null;
}
function At(e) {
  const n = me(e);
  if (Rt(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function Ft(e, n, r) {
  const [l, a] = R(
    () => e.getItem(n) ?? r
  ), s = he((i) => {
    a(i), e.setItem(n, i);
  }, []);
  return fe(() => {
    a(e.getItem(n) ?? r), window.addEventListener("storage", i);
    function i(o) {
      o.storageArea === e && (o.key === null || o.key === n) && a(o.newValue ?? r);
    }
    return () => window.removeEventListener("storage", i);
  }, [e, n]), [l, s];
}
function Ot(e, n, r) {
  const l = x(
    () => JSON.stringify(r),
    [r]
  ), [a, s] = Ft(
    e,
    n,
    l
  ), i = x(() => JSON.parse(a), [a]), o = he(
    (c) => s(JSON.stringify(c)),
    []
  );
  return [i, o];
}
const Ie = kt(
  "HelpContext",
  void 0
), Nt = () => At(Ie), yn = ({ children: e }) => {
  const [n, r] = Ot(localStorage, "helpEnabled", !0);
  function l() {
    r(!n);
  }
  return /* @__PURE__ */ t(Ie.Provider, { value: { enabled: n, toggleHelp: l }, children: e });
}, bn = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: l,
  isDisabled: a,
  buttonVariant: s,
  buttonTestRole: i,
  onContinue: o,
  component: c = A,
  children: d,
  ...m
}) => {
  const [y, f] = R(!1);
  return /* @__PURE__ */ p(_, { children: [
    /* @__PURE__ */ t(
      c,
      {
        variant: s,
        onClick: () => f(!0),
        isDisabled: a,
        "data-testrole": i,
        children: l
      }
    ),
    /* @__PURE__ */ t(
      Pe,
      {
        variant: "small",
        ...m,
        title: e,
        isOpen: y,
        onClose: () => f(!1),
        actions: [
          /* @__PURE__ */ t(
            A,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                f(!1), o();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            A,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => f(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: d
      }
    )
  ] });
}, Ce = ({ message: e, ...n }) => /* @__PURE__ */ t(G, { ...n, children: /* @__PURE__ */ t(j, { children: /* @__PURE__ */ t(z, { icon: /* @__PURE__ */ t(Xe, {}), variant: "error", children: e }) }) }), Te = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: l = !1
}) => {
  const { enabled: a } = Nt();
  return a ? /* @__PURE__ */ t(Ve, { bodyContent: e, children: /* @__PURE__ */ p(_, { children: [
    !l && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (s) => s.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(H, { isInline: r, children: /* @__PURE__ */ t(Z, {}) })
      }
    ),
    l && /* @__PURE__ */ t(H, { isInline: r, children: /* @__PURE__ */ t(Z, {}) })
  ] }) }) : null;
}, F = ({
  name: e,
  label: n,
  labelIcon: r,
  error: l,
  children: a,
  ...s
}) => /* @__PURE__ */ p(
  ce,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t(Te, { helpText: r, fieldLabelId: e }) : void 0,
    ...s,
    children: [
      a,
      l && /* @__PURE__ */ t(Ce, { "data-testid": `${e}-helper`, message: l.message })
    ]
  }
), vn = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: l,
  ...a
}) => {
  const {
    control: s,
    formState: { errors: i }
  } = W();
  return /* @__PURE__ */ t(
    F,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: i[e],
      labelIcon: l,
      children: /* @__PURE__ */ t(
        N,
        {
          ...r,
          name: e,
          control: s,
          render: ({ field: o }) => {
            const c = !!r.rules?.required, d = r.rules?.min, m = o.value === 0 ? r.defaultValue : o.value, y = (f) => o.onChange(d ? Math.max(f, Number(d)) : f);
            return /* @__PURE__ */ t(
              qe,
              {
                ...a,
                id: e,
                value: m,
                validated: i[e] ? w.error : w.default,
                required: c,
                min: Number(d),
                max: Number(r.rules?.max),
                onPlus: () => y(m + 1),
                onMinus: () => y(m - 1),
                onChange: (f) => {
                  const u = Number(f.currentTarget.value);
                  y(isNaN(u) ? r.defaultValue : u);
                }
              }
            );
          }
        }
      )
    }
  );
}, Pt = ({
  hasReveal: e = !0,
  innerRef: n,
  ...r
}) => {
  const { t: l } = pe(), [a, s] = R(!0);
  return /* @__PURE__ */ p(K, { children: [
    /* @__PURE__ */ t(E, { children: /* @__PURE__ */ t(
      D,
      {
        ...r,
        type: a ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      A,
      {
        variant: "control",
        "aria-label": l("showPassword"),
        onClick: () => s(!a),
        children: a ? /* @__PURE__ */ t(Ze, {}) : /* @__PURE__ */ t(et, {})
      }
    )
  ] });
}, we = Ye(
  (e, n) => /* @__PURE__ */ t(Pt, { ...e, innerRef: n })
);
we.displayName = "PasswordInput";
const In = (e) => {
  const { labelIcon: n, ...r } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: s, fieldState: i } = J({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ p(
    F,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: i.error,
      children: [
        /* @__PURE__ */ t(
          we,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? w.error : w.default,
            isDisabled: e.isDisabled,
            ...r,
            ...s
          }
        ),
        e.helperText && /* @__PURE__ */ t(G, { children: /* @__PURE__ */ t(j, { children: /* @__PURE__ */ t(z, { children: e.helperText }) }) })
      ]
    }
  );
}, M = (e) => typeof e == "string", ne = (e) => M(e) ? e : e.key, Vt = ({
  name: e,
  label: n,
  options: r,
  controller: l,
  variant: a = ee.single,
  labelIcon: s,
  onFilter: i,
  ...o
}) => {
  const {
    control: c,
    formState: { errors: d }
  } = W(), [m, y] = R(!1), f = (h = "") => {
    const b = h.toLowerCase();
    return r.filter(
      (g) => (M(g) ? g : g.value).toLowerCase().startsWith(b)
    ).map((g) => /* @__PURE__ */ t(be, { value: ne(g), children: M(g) ? g : g.value }, ne(g)));
  }, u = (h) => typeof h[0] != "string";
  return /* @__PURE__ */ t(
    F,
    {
      name: e,
      label: n,
      isRequired: !!l.rules?.required,
      error: d[e],
      labelIcon: s,
      children: /* @__PURE__ */ t(
        N,
        {
          ...l,
          name: e,
          control: c,
          render: ({ field: { onChange: h, value: b } }) => /* @__PURE__ */ t(
            ye,
            {
              ...o,
              toggleId: e.slice(e.lastIndexOf(".") + 1),
              onToggle: (g, C) => y(C),
              selections: u(r) ? r.filter(
                (g) => Array.isArray(b) ? b.includes(g.key) : b === g.key
              ).map((g) => g.value) : b,
              onSelect: (g, C) => {
                if (g.stopPropagation(), Array.isArray(b)) {
                  const O = C.toString(), I = u(r) ? r.find((v) => v.value === O)?.key : O;
                  b.includes(I) ? h(b.filter((v) => v !== I)) : h([...b, O]);
                } else
                  h(C), y(!1);
              },
              onClear: a !== ee.single ? (g) => {
                g.stopPropagation(), h([]);
              } : void 0,
              onFilter: (g, C) => (i?.(C), f(C)),
              isOpen: m,
              variant: a,
              validated: d[e] ? w.error : w.default,
              children: f()
            }
          )
        }
      )
    }
  );
}, Cn = (e) => {
  const n = e.stringify ? "false" : !1, r = e.defaultValue ?? n, { control: l } = W();
  return /* @__PURE__ */ t(
    F,
    {
      hasNoPaddingTop: !0,
      name: e.name,
      isRequired: e.rules?.required === !0,
      label: e.label,
      labelIcon: e.labelIcon,
      children: /* @__PURE__ */ t(
        N,
        {
          control: l,
          name: e.name,
          defaultValue: r,
          render: ({ field: { onChange: a, value: s } }) => /* @__PURE__ */ t(
            _e,
            {
              ...e,
              id: e.name,
              "data-testid": e.name,
              label: e.labelOn,
              isChecked: e.stringify ? s === "true" : s,
              onChange: (i, o) => {
                const c = e.stringify ? o.toString() : o;
                e.onChange?.(i, o), a(c);
              }
            }
          )
        }
      )
    }
  );
}, Tn = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: l, fieldState: a } = J({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    F,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: a.error,
      children: /* @__PURE__ */ t(
        de,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: a.error ? w.error : w.default,
          isDisabled: e.isDisabled,
          ...l
        }
      )
    }
  );
}, wn = (e) => {
  const { labelIcon: n, ...r } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: s, fieldState: i } = J({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ p(
    F,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: i.error,
      children: [
        /* @__PURE__ */ t(
          D,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? w.error : w.default,
            isDisabled: e.isDisabled,
            ...r,
            ...s
          }
        ),
        e.helperText && /* @__PURE__ */ t(G, { children: /* @__PURE__ */ t(j, { children: /* @__PURE__ */ t(z, { children: e.helperText }) }) })
      ]
    }
  );
}, qt = de, Sn = ({ icon: e }) => {
  const n = _t(e);
  return /* @__PURE__ */ t(H, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function _t(e) {
  switch (e) {
    case "github":
      return ht;
    case "facebook":
      return mt;
    case "gitlab":
      return ut;
    case "google":
      return dt;
    case "linkedin":
    case "linkedin-openid-connect":
      return ct;
    case "openshift-v3":
    case "openshift-v4":
      return ot;
    case "stackoverflow":
      return it;
    case "twitter":
      return st;
    case "microsoft":
      return at;
    case "bitbucket":
      return lt;
    case "instagram":
      return rt;
    case "paypal":
      return nt;
    default:
      return tt;
  }
}
const Dt = "_title_jpdeu_3", Bt = {
  title: Dt
}, Se = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: l = "xl",
  ...a
}) => /* @__PURE__ */ t(
  De,
  {
    headingLevel: r,
    size: l,
    className: Bt.title,
    id: e,
    tabIndex: 0,
    ...a,
    children: n
  }
), Lt = ({
  title: e,
  children: n,
  scrollId: r,
  className: l
}) => {
  const a = Qe();
  return /* @__PURE__ */ p(Be, { id: a, className: l, isFlat: !0, children: [
    /* @__PURE__ */ t(Le, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(He, { tabIndex: 0, children: /* @__PURE__ */ t(Se, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(Ee, { className: "kc-form-panel__body", children: n })
  ] });
}, Ht = (e) => {
  const { title: n, children: r, scrollId: l, ...a } = e;
  return /* @__PURE__ */ t("section", { ...a, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ p(_, { children: [
    /* @__PURE__ */ t(Se, { id: l, title: n }),
    r
  ] }) });
}, Et = "_panel_1kfdo_1", Mt = "_sticky_1kfdo_9", re = {
  panel: Et,
  sticky: Mt
}, $t = "kc-main-content-page-container", le = (e) => e.replace(/\s+/g, "-"), Ut = ({
  label: e,
  sections: n,
  borders: r = !1,
  ...l
}) => {
  const a = x(
    () => n.filter(({ isHidden: s }) => !s),
    [n]
  );
  return /* @__PURE__ */ p(Me, { hasGutter: !0, ...l, children: [
    /* @__PURE__ */ t(Q, { md: 8, sm: 12, children: a.map(({ title: s, panel: i }) => {
      const o = le(s.toLowerCase());
      return /* @__PURE__ */ t(ge, { children: r ? /* @__PURE__ */ t(
        Lt,
        {
          scrollId: o,
          title: s,
          className: re.panel,
          children: i
        }
      ) : /* @__PURE__ */ t(Ht, { scrollId: o, title: s, children: i }) }, s);
    }) }),
    /* @__PURE__ */ t(Q, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ t($e, { className: re.sticky, children: /* @__PURE__ */ t(
      Ue,
      {
        isVertical: !0,
        scrollableSelector: `#${$t}`,
        label: e,
        offset: 100,
        children: a.map(({ title: s }) => {
          const i = le(s.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ t(
              Ge,
              {
                href: `#${i}`,
                "data-testid": `jump-link-${i}`,
                children: s
              },
              s
            )
          );
        })
      }
    ) }) })
  ] });
}, Gt = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, jt = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: l
}) => {
  const a = x(
    () => r.map((s) => ({
      key: s,
      value: e(`locale_${s}`, Gt(s) ?? s)
    })).sort((s, i) => s.value.localeCompare(i.value, l)),
    [r, l, e]
  );
  return a.length ? /* @__PURE__ */ t(pt, { ...n, children: /* @__PURE__ */ t(
    Vt,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: a,
      variant: a.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, xe = (e) => e?.includes("${"), ke = (e) => e.substring(2, e.length - 1), k = (e, n, r) => (xe(n) ? e(ke(n)) : n) || r, $ = (e, n) => k(e, n.displayName, n.name), zt = ["username", "firstName", "lastName", "email"], Re = (e) => e && zt.includes(e), S = (e) => `${Re(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, xn = (e) => e.replaceAll(".", "🍺"), kn = (e) => e.replaceAll("🍺", ".");
function Rn(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((l) => {
    const a = Object.assign(
      {},
      l.params?.map((s) => r(xe(s.toString()) ? ke(s) : s))
    );
    n(S(l.field), {
      message: r(l.errorMessage, {
        ...a,
        defaultValue: l.errorMessage || l.field
      }),
      type: "server"
    });
  });
}
function P({
  required: e,
  validators: n
}) {
  return e || Kt(n);
}
function Kt(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function An(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return ae(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(ae);
}
function ae(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const V = ({
  t: e,
  form: n,
  attribute: r,
  renderer: l,
  children: a
}) => {
  const s = k(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: i }
  } = n, o = l?.(r), c = St(i, S(r.name));
  return /* @__PURE__ */ p(
    ce,
    {
      label: $(e, r) || "",
      fieldId: r.name,
      isRequired: P(r),
      labelIcon: s ? /* @__PURE__ */ t(Te, { helpText: s, fieldLabelId: r.name }) : void 0,
      children: [
        o ? /* @__PURE__ */ p(K, { children: [
          a,
          o
        ] }) : a,
        c && /* @__PURE__ */ t(
          Ce,
          {
            "data-testid": `${r.name}-helper`,
            message: c.message
          }
        )
      ]
    },
    r.name
  );
}, Wt = ({
  t: e,
  form: n,
  attribute: r,
  renderer: l,
  ...a
}) => /* @__PURE__ */ t(V, { t: e, form: n, attribute: r, renderer: l, children: /* @__PURE__ */ t(
  Jt,
  {
    t: e,
    form: n,
    "aria-label": $(e, r),
    name: S(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: $(e, r)
    }),
    ...a
  }
) }), Jt = ({
  t: e,
  name: n,
  inputType: r,
  form: l,
  addButtonLabel: a,
  isDisabled: s = !1,
  defaultValue: i,
  id: o,
  ...c
}) => {
  const { register: d, setValue: m, control: y } = l, f = yt({
    name: n,
    control: y,
    defaultValue: i || ""
  }), u = x(() => Array.isArray(f) && f.length !== 0 ? f : i || [""], [f]), h = (I) => {
    C([...u.slice(0, I), ...u.slice(I + 1)]);
  }, b = () => {
    C([...u, ""]);
  }, g = (I, v) => {
    C([...u.slice(0, I), v, ...u.slice(I + 1)]);
  }, C = (I) => {
    const v = I.flatMap((Y) => Y);
    m(n, v, {
      shouldDirty: !0
    });
  }, O = r.startsWith("html") ? r.substring(6) : "text";
  return fe(() => {
    d(n);
  }, [d]), /* @__PURE__ */ t("div", { id: o, children: u.map((I, v) => /* @__PURE__ */ p(ge, { children: [
    /* @__PURE__ */ p(K, { children: [
      /* @__PURE__ */ t(E, { isFill: !0, children: /* @__PURE__ */ t(
        D,
        {
          "data-testid": n + v,
          onChange: (Y, Ae) => g(v, Ae),
          name: `${n}.${v}.value`,
          value: I,
          isDisabled: s,
          type: O,
          ...c
        }
      ) }),
      /* @__PURE__ */ t(E, { children: /* @__PURE__ */ t(
        A,
        {
          "data-testid": "remove" + v,
          variant: X.link,
          onClick: () => h(v),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: u.length === 1 || s,
          children: /* @__PURE__ */ t(ft, {})
        }
      ) })
    ] }),
    v === u.length - 1 && /* @__PURE__ */ p(
      A,
      {
        variant: X.link,
        onClick: b,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !I || s,
        children: [
          /* @__PURE__ */ t(gt, {}),
          " ",
          e(a || "add")
        ]
      }
    )
  ] }, v)) });
}, se = (e) => {
  const { form: n, inputType: r, attribute: l } = e, a = P(l), s = r.startsWith("multiselect"), i = s ? je : ze, o = l.validators?.options?.options || [], c = l.annotations?.inputOptionLabels || {};
  return /* @__PURE__ */ t(V, { ...e, children: /* @__PURE__ */ t(
    N,
    {
      name: S(l.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: d }) => /* @__PURE__ */ t(_, { children: o.map((m) => /* @__PURE__ */ t(
        i,
        {
          id: m,
          "data-testid": m,
          label: k(e.t, c[m], m),
          value: m,
          isChecked: d.value.includes(m),
          onChange: () => {
            s ? d.value.includes(m) ? d.onChange(
              d.value.filter((y) => y !== m)
            ) : d.onChange([...d.value, m]) : d.onChange([m]);
          },
          readOnly: l.readOnly,
          isRequired: a
        },
        m
      )) })
    }
  ) });
}, ie = (e) => {
  const { t: n, form: r, inputType: l, attribute: a } = e, [s, i] = R(!1), o = P(a), c = l === "multiselect", d = (u, h) => {
    c ? h.value.includes(u) ? h.onChange(h.value.filter((b) => b !== u)) : h.onChange([...h.value, u]) : h.onChange(u);
  }, m = a.validators?.options?.options || [], y = a.annotations?.inputOptionLabels || {}, f = (u) => k(e.t, y[u], u);
  return /* @__PURE__ */ t(V, { ...e, children: /* @__PURE__ */ t(
    N,
    {
      name: S(a.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: u }) => /* @__PURE__ */ t(
        ye,
        {
          toggleId: a.name,
          onToggle: (h, b) => i(b),
          onSelect: (h, b) => {
            const g = b.toString();
            d(g, u), Array.isArray(u.value) || i(!1);
          },
          selections: u.value ? u.value : c ? [] : n("choose"),
          variant: c ? "typeaheadmulti" : m.length >= 10 ? "typeahead" : "single",
          "aria-label": n("selectOne"),
          isOpen: s,
          isDisabled: a.readOnly,
          required: o,
          children: ["", ...m].map((h) => /* @__PURE__ */ t(
            be,
            {
              selected: u.value === h,
              value: h,
              children: h ? f(h) : n("choose")
            },
            h
          ))
        }
      )
    }
  ) });
}, Yt = (e) => {
  const { form: n, attribute: r } = e, l = P(r);
  return /* @__PURE__ */ t(V, { ...e, children: /* @__PURE__ */ t(
    qt,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(S(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: l
    }
  ) });
}, T = (e) => {
  const { form: n, inputType: r, attribute: l } = e, a = P(l), s = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t(V, { ...e, children: /* @__PURE__ */ t(
    D,
    {
      id: l.name,
      "data-testid": l.name,
      type: s,
      placeholder: k(
        e.t,
        l.annotations?.inputTypePlaceholder
      ),
      readOnly: l.readOnly,
      isRequired: a,
      ...n.register(S(l.name))
    }
  ) });
}, U = {
  text: T,
  textarea: Yt,
  select: ie,
  "select-radiobuttons": se,
  multiselect: ie,
  "multiselect-checkboxes": se,
  "html5-email": T,
  "html5-tel": T,
  "html5-url": T,
  "html5-number": T,
  "html5-range": T,
  "html5-datetime-local": T,
  "html5-date": T,
  "html5-month": T,
  "html5-time": T,
  "multi-input": Wt
}, Fn = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: l,
  currentLocale: a,
  hideReadOnly: s = !1,
  renderer: i
}) => {
  const o = x(() => {
    if (!r.attributes)
      return [];
    const c = s ? r.attributes.filter(({ readOnly: d }) => !d) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((d) => ({
      group: d,
      attributes: c.filter(
        (m) => m.group === d.name
      )
    }));
  }, [
    s,
    r.groups,
    r.attributes
  ]);
  return o.length === 0 ? null : /* @__PURE__ */ t(
    Ut,
    {
      label: e("jumpToSection"),
      sections: o.filter((c) => c.attributes.length > 0).map(({ group: c, attributes: d }) => ({
        title: k(e, c.displayHeader, c.name) || e("general"),
        panel: /* @__PURE__ */ p("div", { className: "pf-v5-c-form", children: [
          c.displayDescription && /* @__PURE__ */ t(Ke, { className: "pf-v5-u-pb-lg", children: k(e, c.displayDescription, "") }),
          d.map((m) => /* @__PURE__ */ t(
            Qt,
            {
              t: e,
              form: n,
              supportedLocales: l,
              currentLocale: a,
              renderer: i,
              attribute: m
            },
            m.name
          ))
        ] })
      }))
    }
  );
}, Qt = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: l,
  currentLocale: a,
  attribute: s
}) => {
  const i = n.watch(
    S(s.name)
  ), o = x(() => Zt(s), [s]), c = s.multivalued || tn(i) ? U["multi-input"] : U[o];
  return s.name === "locale" ? /* @__PURE__ */ t(
    jt,
    {
      form: n,
      supportedLocales: l,
      currentLocale: a,
      t: e,
      attribute: s
    }
  ) : /* @__PURE__ */ t(
    c,
    {
      t: e,
      form: n,
      inputType: o,
      attribute: s,
      renderer: r
    }
  );
}, Xt = "text";
function Zt(e) {
  if (Re(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return en(n) ? n : Xt;
}
const en = (e) => typeof e == "string" && e in U, tn = (e) => Array.isArray(e) && e.length > 1, nn = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ p(
  "svg",
  {
    className: xt(
      q.avatar,
      q.modifiers[r],
      n === "light" && q.modifiers.light,
      n === "dark" && q.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ p("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), oe = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...l
}) => {
  const [a, s] = R(!1);
  return /* @__PURE__ */ t(
    bt,
    {
      ...l,
      isPlain: !0,
      position: "right",
      toggle: e ? /* @__PURE__ */ t(vt, { onToggle: (i, o) => s(o), children: n }) : /* @__PURE__ */ t(It, { onToggle: (i, o) => s(o), children: n }),
      isOpen: a,
      dropdownItems: r
    }
  );
};
function rn(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, l = e.family_name, a = e.preferred_username;
  return r && l ? n("fullName", { givenName: r, familyName: l }) : r || l || a || n("unknownUser");
}
const On = ({
  keycloak: e,
  brand: { href: n, ...r },
  avatar: l,
  features: {
    hasLogout: a = !0,
    hasManageAccount: s = !0,
    hasUsername: i = !0
  } = {},
  kebabDropdownItems: o,
  dropdownItems: c = [],
  toolbarItems: d,
  ...m
}) => {
  const { t: y } = pe(), f = [];
  s && f.push(
    /* @__PURE__ */ t(
      te,
      {
        onClick: () => e.accountManagement(),
        children: y("manageAccount")
      },
      "manageAccount"
    )
  ), a && f.push(
    /* @__PURE__ */ t(te, { onClick: () => e.logout(), children: y("signOut") }, "signOut")
  );
  const u = e.idTokenParsed?.picture;
  return /* @__PURE__ */ t(
    Ct,
    {
      ...m,
      logo: /* @__PURE__ */ t(We, { ...r }),
      logoProps: { href: n },
      headerTools: /* @__PURE__ */ p(Tt, { children: [
        /* @__PURE__ */ p(wt, { children: [
          /* @__PURE__ */ t(
            L,
            {
              visibility: {
                md: "hidden"
              },
              children: /* @__PURE__ */ t(
                oe,
                {
                  "data-testid": "options-kebab",
                  isKebab: !0,
                  dropDownItems: [
                    ...o || c,
                    f
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ t(L, { children: d }),
          /* @__PURE__ */ t(
            L,
            {
              visibility: {
                default: "hidden",
                md: "visible"
              },
              children: /* @__PURE__ */ t(
                oe,
                {
                  "data-testid": "options",
                  dropDownItems: [...c, f],
                  title: i ? rn(e.idTokenParsed, y) : void 0
                }
              )
            }
          )
        ] }),
        u || l?.src ? /* @__PURE__ */ t(Je, { src: u, alt: y("avatar"), ...l }) : /* @__PURE__ */ t(nn, { ...l })
      ] })
    }
  );
};
export {
  pn as AlertProvider,
  bn as ContinueCancelModal,
  Ce as FormErrorText,
  Lt as FormPanel,
  yn as Help,
  Te as HelpItem,
  Sn as IconMapper,
  On as KeycloakMasthead,
  qt as KeycloakTextArea,
  vn as NumberControl,
  In as PasswordControl,
  we as PasswordInput,
  Ut as ScrollForm,
  Vt as SelectControl,
  Cn as SwitchControl,
  Tn as TextAreaControl,
  wn as TextControl,
  Fn as UserProfileFields,
  xn as beerify,
  kt as createNamedContext,
  kn as debeerify,
  Rt as isDefined,
  An as isUserProfileError,
  k as label,
  $t as mainPageContentId,
  Rn as setUserProfileServerError,
  gn as useAlerts,
  Nt as useHelp,
  At as useRequiredContext,
  Ot as useStoredState
};
